import { inject, Injectable } from '@angular/core';
import { IForm, IFormSection, IFormSectionQuestion } from '../models/form.interface';
import { IOption } from '../../../shared/models/question.interface';
import { SectionTabsService } from '../../../shared/components/section-tabs/services/section-tabs.service';
import { IFormDetails } from '../models/form-details.interface';
import { QuestionService } from '../../../shared/services/question.service';
import { UtilService } from '../../../shared/services/util.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { FormArray, FormGroup } from '@angular/forms';
import { QuestionTypeNames } from '../../../shared/types/question.types';

@Injectable({ providedIn: 'root' })
export class FormBuilderService {
  baseUrl = environment.apiBaseUrl;

  sectionTabsService = inject(SectionTabsService);
  questionService = inject(QuestionService);
  httpClient = inject(HttpClient);

  createForm(form: IForm) {
    return this.httpClient.post(`${this.baseUrl}form`, form);
  }

  getAllSectionsControlsArray(form: FormGroup): FormArray {
    return form.get('sections') as FormArray;
  }

  getSectionFormGroup(sectionFormArray: FormArray, sectionIndex: number): FormGroup {
    return sectionFormArray.at(sectionIndex) as FormGroup;
  }

  combineFormDetailsWithSectionForms(formDetails: IFormDetails, sections: { [key: string]: { [key: string]: any } }[]) {
    const form: IForm = {
      title: formDetails.formTitle,
      description: formDetails.description,
      associatedCountryOfCitizenshipIds: formDetails.countryAssociated,
      associatedCountryOfTravelIds: formDetails.travelDestination,
      tenantIds: formDetails.embassies,
      price: Number(formDetails.price),
      sections: this.prepareSectionsWithQuestions(sections),
    };

    return form;
  }

  checkIfAllSectionsHaveQuestions(sections: { [key: string]: any }[]): boolean {
    return sections.every((section) => Object.keys(section).length > 0);
  }

  /**
   * Iterate through each section in the formArray and check if any of the controls are invalid.
   * If any are, add the index of the section to the tabsWithError array.
   * @param formArray The FormArray to check for errors.
   * @returns An array of the indices of the sections that have errors.
   */
  getTabsWithError(formArray: FormArray): number[] {
    const tabsWithError: number[] = [];

    for (let i = 0; i < formArray.length; i++) {
      if (formArray.at(i).invalid || Object.keys((formArray.at(i) as FormGroup).controls).length === 0) {
        tabsWithError.push(i);
        continue;
      }
    }

    return tabsWithError;
  }

  isTabControlsFreeFromErrors(formGroup: FormGroup): boolean {
    if (formGroup) {
      for (const control of Object.values(formGroup.controls)) {
        if (control.invalid) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Orders the options for the question.
   * @param options the options string array
   */
  orderOptions(options: string[]): IOption[] {
    const _options: IOption[] = [];

    options.forEach((option: string, index: number) => {
      _options.push({
        value: option,
        displayText: option,
        isDefault: false,
        order: index,
      });
    });

    return _options;
  }

  /****************************
   * PRIVATE METHODS
   *************************** */

  /**
   * Prepares the sections with the questions. The questions are in an array form where the
   * first in the array is the one to be displayed the last so the order key's value is
   * reversed so that the last question will have the order to be 0.
   *
   * @param sections the sections with questions
   *
   * @returns the prepared sections with questions
   */

  private prepareSectionsWithQuestions(sections: { [key: string]: { [key: string]: any } }[]): IFormSection[] {
    const formSections: IFormSection[] = [];
    const sectionTabs = this.sectionTabsService.tabs();

    sections.forEach((section: { [key: string]: { [key: string]: any } }, index: number) => {
      const formQuestions: IFormSectionQuestion[] = [];
      let formSection: IFormSection = { questions: [] };

      /**
       * The questions are in an array form where the first in the array is the one to be
       * displayed last so the order key's value is reversed so that the last question
       * will have the order to be 0
       */
      let questionOrder = Object.entries(section).length;

      for (const [key, value] of Object.entries(section)) {
        const options: string[] = value['options'] || [];
        const orderedOptions: IOption[] = [];
        const questionTypeName = UtilService.split(key, '-')[0];

        const questionTypeValue = this.questionService.getQuestionTypeByName(questionTypeName as QuestionTypeNames);

        // Check if question has options
        if (options.length) {
          orderedOptions.push(...this.orderOptions(options));
        }

        // create section question object
        const formQuestion: IFormSectionQuestion = this.questionService.mapValuesToQuestionPOSTRequest(
          {
            label: value['label'],
            validation: value['validation'],
            helperText: value['helperText'],
            required: value['required'],
            isMultiSelect: value['isMultiSelect'],
            maximumFileSize: value['maximumFileSize'],
            allowImageCapture: value['allowImageCapture'],
            allowImageUpload: value['allowImageUpload'],
            allowMultipleFileUpload: value['allowMultipleFileUpload'],
          },
          questionTypeValue.value,
          orderedOptions,
          --questionOrder,
        );

        formQuestions.push(formQuestion);
      }

      formSection = {
        title: sectionTabs[index]?.label,
        questions: [...formQuestions],
        order: index,
      };

      formSections.push(formSection);
    });

    return formSections;
  }

  /**
   * Given a control name in the format {controlType}-{controlId}, returns the QuestionTypeNames value.
   * For example, given "shortAnswer-1234567890", returns QuestionTypeNames.ShortAnswer.
   * @param controlName The control name in the format {controlType}-{controlId}.
   * @returns The QuestionTypeNames value of the control type.
   */
  getControlType(controlName: string): QuestionTypeNames {
    // TODO: Remove this and use the one in the question service
    return UtilService.split(controlName, '-')[0] as QuestionTypeNames;
  }
}
